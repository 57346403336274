<template>

    <div class="dashboard">

        <div class="sidebar">
            <div class="blocsidebar">
                <div class="lignesidebar"></div>
                <div class="homesidebar">
                    <img src="../assets/Home.svg">
                    <p> Dashboard </p>
                </div>
            </div>
        </div>

        <div class="titredashboard">
            <h1> Statistiques </h1>
        </div>

        <div class="deconnexion">
            <a href=""> Déconnexion </a>
        </div>

    </div>

</template>

<script>

    export default {
        
        name: 'dashboard'

    }

</script>

<style scoped>

    .sidebar {

        width: 215px;
        background-color: #2c3e50;
        height: 852px;
        position: absolute;

    }

    .sidebar p {

        color: white;
        font-weight: bold;

    }

    .lignesidebar {

        width: 181px;
        height: 1px;
        background-color: #FFFFFF;
        margin: 0 auto;

    }

    .blocsidebar {

        padding-top: 110px;

    }

    .homesidebar {

        display: flex;
        justify-content: space-evenly;
        padding-top: 30px;

    }

    .titredashboard h1 {

        display: flex;
        justify-content: center;
        font-size: 36px;
        color: #2c3e50;
        padding-top: 80px;

    }

    .deconnexion {

        position: absolute;
        right: 60px;
        top: 30px;

    }

    .deconnexion a {

        color: #5887FF;

    }

</style>